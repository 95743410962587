<div class="title-container">
    <div>
        <img src="/assets/icons/couronne-or.png">
        <h2>Gallerie</h2>
    </div>
    <h3>Artworks officiels</h3>
</div>
<div *ngIf="illustrations">
    <p-galleria  [(value)]="illustrations[0]" [responsiveOptions]="responsiveOptions" [numVisible]="5"> 
        <ng-template pTemplate="item" let-item>
            <img [src]="item.previewImageSrc" style="object-fit: contain; display: block;" alt="illustration de {{item.previewImageSrc}}"/>
            <p id="img-description">{{item.alt}}</p>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.thumbnailImageSrc" style="fit-content; display: block;"/>
            </div>
        </ng-template>
    </p-galleria>
</div>


<div class="title-container">
    <div>
        <img src="/assets/icons/couronne-or.png">
        <h2>Gallerie</h2>
    </div>
    <h3>Artworks fans</h3>
</div>

<div *ngIf="illustrations">
    <p-galleria class="fanarts-galleria" [(value)]="illustrations[1]" [responsiveOptions]="responsiveOptions" [numVisible]="5"> 
        <ng-template pTemplate="item" let-item>
            <img [src]="item.previewImageSrc" style="display: block;" alt="illustration de {{item.previewImageSrc}}"/>
            <p id="img-description">{{item.alt}}</p>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item.thumbnailImageSrc" style="width: 30%; display: block;"/>
                <span>{{item.title}}</span>
            </div>
        </ng-template>
    </p-galleria>
</div>

