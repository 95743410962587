<div class="players-container" 
inViewport
[inViewportOptions]="{ threshold: [0] }"
(inViewportAction)="onIntersection($event)">
    <div id="container" [ngStyle]="{'flex-direction' : flexbox}">
        <div class="img-container" [ngStyle]="{'margin': margin}">
            <img class="character" src="{{character}}" alt="Illustration de {{character}}">
        </div>
        <div id="content" [ngStyle]="{'margin' : secondMargin}">
            <h2>{{subtitle}}</h2>
            <h3>{{title}}</h3>
            <h4>{{nickname}}</h4>
            <p [innerHTML]="paragraph"></p>
            <div class="to-twitter-container">
                <p> {{isPlayedBy}}
                    <a (mouseover)="toggleTwitter=true" 
                        (mouseout)="toggleTwitter=false"
                        href="{{link}}">{{player}}
                    </a>
                </p>
                <img [@inOutAnimation] *ngIf="toggleTwitter" src="/assets/icons/logo-twitter.png" target="_blank">
            </div>
            <div class="quotes-container">
                <img [ngClass]="{'buble-left': buble === 'left',
                                'buble-right': buble === 'right'
                                }" 
                src="{{buble === 'left' ? '/assets/icons/dialog-left.png' : '/assets/icons/dialog-right.png'}}" alt="bulle de dialogue">
                <p [innerHTML]="quotes"></p>
            </div>
        </div>
    </div>
</div>  
