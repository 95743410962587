import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { GRIDLANDSCAPE, GridLandscape, Landscape, LANDSCAPES } from '../models/landscape';

@Component({
  selector: 'app-cartography',
  templateUrl: './cartography.component.html',
  styleUrls: ['./cartography.component.scss']
})

export class CartographyComponent implements OnInit {
  
  public gridLandscape: GridLandscape[] = GRIDLANDSCAPE;
  public landscapeDetails: Landscape[] = LANDSCAPES;
  public toggleScrollerTop: boolean = false;

  constructor(private renderer: Renderer2, private vps: ViewportScroller) { }

  ngOnInit(): void {
  }
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'displayed-left' : 'hidden');
  } 
  scroll(id) {
    this.vps.scrollToAnchor(id);
  }
}
