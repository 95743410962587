<app-header></app-header>
<div id="hero">
    <div class="displayed-left">
        <h1>Le monde d'Aria est riche</h1>
        <p class="sub-title">Nous vous proposons de découvrir une partie de <br> ses paysages et de son histoire.</p>
    </div>
</div>

<div class="landscapes-collection-container">
    <div (click)="scroll(landscape.name)" class="landscape displayed-left" [ngStyle]="{'animation-delay': i/5 + 's'}" *ngFor="let landscape of gridLandscape; let i = index">
        <span class="landscape-name"><p>{{landscape.name}}</p></span>
        <img  src="{{landscape.picture}}">
    </div>
</div> 

<div inViewport
[inViewportOptions]="{ threshold: [0] }"
(inViewportAction)="onIntersection($event)">
<div id="map-monde-title">
    <h1>La carte du monde d'Aria</h1>
    <p class="sub-title">Un image vaut mieux que mille mots.</p>
</div>
    <div inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="onIntersection($event)" id="mapmonde-container">
        <img class="mapmonde" src="assets/maps/legends/MapMonde.jpg">
    </div>
</div>

<app-landscapes id="{{landscapeDetail.title}}" *ngFor="let landscapeDetail of landscapeDetails"
[title]="landscapeDetail.title"
[subtitle]="landscapeDetail.subtitle"
[landscape]="landscapeDetail.landscape"
[map]="landscapeDetail.map"
[paragraph]="landscapeDetail.paragraph"
[flexbox]="landscapeDetail.flexbox"
[color]="landscapeDetail.color"
[slideInDirection]="landscapeDetail.slideInDirection">
</app-landscapes>

<app-footer></app-footer>