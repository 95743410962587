export class Player {
    subtitle: string;
    title: string;
    nickname: string;
    paragraph: string;
    isPlayedBy: string;
    player: string;
    link: string;
    quotes: string;
    buble: string;
    character: string;
    flexbox: string;
    margin: string;
    secondMargin?: string;
    slideInDirection: string;
}
export const PLAYERS: Player[] = [
    {
        subtitle: 'le voleur aux verbiages',
        title: 'Atlan Grethen de Quirk',
        nickname: 'Alfredo de la Puerta, Latan Quirken de la Porte',
        paragraph: 'Né dans une famille noble, il est en fuite depuis 5 ans après que son valet l\'ait averti qu\'une organisation voulait attenter à sa vie. Pris de court, il n\'a pu emporter avec lui qu\'une dague portant un étrange symbole sur la poignée de cette dernière. <br> <br> <br> Il se démarque au fil de la campagne <b> par la qualité de son verbiage et son extraordinaire capacité à entourlouper ses interlocuteurs. </b> Il multiplie également les titres et aimer à penser qu\'il est bon Prêtre du Pain. Tant qu\'il s\'agit de bénir des miches... <br> <br> <br>',
        isPlayedBy: 'Atlan est brillamment interprété',
        player: 'par le haut en couleurs Deriv',
        link: 'https://twitter.com/MrDeriv',
        quotes: '<i>« Hola mon brave ! » <br> <br> « J\'aimerais bénir vos miches... » <br>  <br> « Oh vous savez avocat... avant d\'être une profession, c\'est une vocation ! » </i>',
        buble: 'left',
        character: '/assets/characters/Atlan.png',
        flexbox: 'row-reverse',
        margin: '0 0 0 50px',
        secondMargin: '0 75px 0 0',
        slideInDirection: 'displayed-right',
    },
    {
        subtitle: 'Le mage au témpérament ardent',
        title: 'Clodomir de Cuivrechamps',
        nickname: 'Prince des échecs, Clodowood, Clodométal',
        paragraph: 'Clodomir recherche son maître de magie après avoir fait un voyage initiatique d\'un an et devenir un sorcier à part entière. <br> <br> <br> Au commencement tout du moins, Clodomir est un jeune homme frêle, peu assuré au teint pâle. Il rencontre Atlan et Niklas lors de son voyage alors que ces derniers tentent de vendre des potions pour se faire de l\'argent. <br> <br> <br> Il ponctue les aventures de la Compagnie Créances <b> par tout autant d\'accès de folies ardentes que de puissants sorts dévastateurs. </b> <br> <br> <br>',
        isPlayedBy: 'Clodomir est brillamment interprété',
        player: 'par l\'imprévisible Daz.',
        link: 'https://twitter.com/dazjdm',
        quotes: '<i>« Non mais sinon, une potion d\'Ingramus et c\'est réglé...» <br>  <br> « Vous n\'avez jamais connu telle substance. On appelle ça : le doigt. » <br>  <br> « J\'ai 99 à ma compétence. C\'est I M P O S S I B L E  que je rate.  » </i>',
        buble: 'right',
        character: '/assets/characters/Clodomir.png',
        flexbox: 'row',
        margin: '0 50px 0 0',
        slideInDirection: 'displayed-left',
    },
    {
        subtitle: 'Le gladiatrice insatiable',
        title: 'Kaitra d\'Ashanul',
        nickname: 'Amie des animaux, cuisinière d\'exception',
        paragraph: 'Kaitra recherche gloire et fortune tout en espérant oublier son passé d\'esclave. Livrée à elle-même dans les arènes d\'Ashanul, elle se découvre un don pour le combat jusqu\'à devenir une gladatrice experimentée. <br> <br> <br>  Elle parvient à acheter sa liberté et c\'est au terme de nombreux voyages qu\'elle finit par rencontrer Atlan, Niklas et Clodomir alors qu\'ils se font attaquer par des bandits dans une forêt. <br> <br> <br> Keitra marque la campagne d\'Aria <b> par son amour du "loot" ou collecter tout ce qui est possible d\'obtenir </b> : objets, armes, récompenses, titres et même animaux. <br> <br>',
        isPlayedBy: 'Keitra est brillamment interprétée',
        player: 'par la douce Lydia.',
        link: 'https://twitter.com/lydia__am',
        quotes: '<i>« Oh, génial ! Un concours ! Bon je m\'inscris hein...» <br>  <br> « Cool un nouvel animal ! Je peux l\'apprivoiser ? » <br>  <br> « Et là, je... je lui craque le cou en douceur... » </i>',
        buble: 'left',
        character: '/assets/characters/Keitra.png',
        flexbox: 'row-reverse',
        margin: '0 0 0 50px',
        secondMargin: '0 75px 0 0',
        slideInDirection: 'displayed-right'
    },
    {
        subtitle: 'Un homme de substance⸱s',
        title: 'Niklas Von Trunkel',
        nickname: 'Nikoletta',
        paragraph: 'Niklas doit trouver un artefact légendaire, le «Sceptre Herméneutique», le rapporter à l\'université de Kniga et ainsi laver son honneur. Toujours prompt à faire la fête et tester de nouvelles choses, Niklas est un excellent alchimiste capable de fabriquer des potions dévastatrices. <br> <br> <br> Personnage haut en couleurs, <b> il apporte à la Compagnie Créance une ouverture certaine sur les verrous de l\'esprit et du corps...  <br> <br> <br>',
        isPlayedBy: 'Niklas est brillamment interprété',
        player: 'par l\'hédoniste Lam.',
        link: 'https://twitter.com/LamHua',
        quotes: '<i>« On aura peu joui mais on aura bien ri » <br>  <br> « Y\'a pas moyen que cette fois-ci je ne loot rien. Ça fait 2 ans que je me trimballe en boubou-fraise ! » <br> <br> « Afin de sceller mon amitié avec ce chameau, j\'échange mon piercing au téton avec le sien » </i>',
        buble: 'right',
        character: '/assets/characters/Niklas.png',
        flexbox: 'row',
        margin: '0 50px 0 0',
        slideInDirection: 'displayed-left'
    }
]
