import { Component, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ScrollService } from 'src/app/shared/aria/scroll.service';
import { CONTENTS } from '../../models/content';

@Component({
  selector: 'app-explanations',
  templateUrl: './explanations.component.html',
  styleUrls: ['./explanations.component.scss']
})
export class ExplanationsComponent implements OnInit {

  public picture: string = '';

  @Input() public subtitle: string = '';
  @Input() public title: string = '';
  @Input() public paragraph: string = '';
  @Input() public numbered: number = 0;
  @Input() public keys: string = '';
  @Input() public flexbox: string = '';
  @Input() public slideInDirection: string = '';
  @Input() public keyAnimation: string = '';
  @Input() public transform: string = '';
  public readonly items: number[] = Array(1000).fill(1).map((item, index) => item + index);

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.numbered === 1) {
      this.picture = CONTENTS[0].picture;
    } else if (this.numbered === 2) {
      this.picture = CONTENTS[1].picture;
    } else {
      this.picture = CONTENTS[2].picture;
    }
  }
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? this.slideInDirection : 'hidden');
  }

}
