export class Product {
    name: string;
    shortName: string;
    picture: string;
    isNumericVersion: boolean;
    url: string;
    numericUrl?: string;
    numericPicture?: string;
}

export const PRODUCTS: Product[] = [
    {
        name: 'ARIA : Préludes Édition Deluxe',
        shortName: 'Aria préludes',
        picture: '/assets/shop/Aria prélude coffret deluxe.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/preludes-edition-deluxe-game-of-roles-aria',
        numericUrl: 'https://elder-craft.com/products/preludes-coffret-jeu-de-role-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/num Aria prélude coffret.png.png'
    },
    {
        name: 'ARIA : La guerre des deux royaumes',
        shortName: 'La guerre des deux royaumes',
        picture: '/assets/shop/Aria guerre 2 royaumes.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/la-guerre-des-deux-royaumes-game-of-roles-aria',
        numericUrl: 'https://elder-craft.com/products/la-guerre-des-deux-royaumes-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/num Aria guerre 2 royaumes.png'
    },
    {
        name: 'ARIA : La couronne, le sceptre, et l’orbe',
        shortName: 'La couronne, le sceptre et l\'orbe',
        picture: '/assets/shop/aria couronne sceptre orbe.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/la-couronne-le-sceptre-et-l-orbe-game-of-roles-aria',
        numericUrl:'https://elder-craft.com/products/la-couronne-le-sceptre-et-l-orbe-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/num aria couronne sceptre orbe.png.png'
    },
    {
        name: 'ARIA : Coffret - Voyage en Osmanlie',
        shortName: 'Voyage en Osmanlie',
        picture: '/assets/shop/voyage en osmanlie.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/coffret-voyage-en-osmanlie-game-of-roles-aria',
        numericUrl: 'https://elder-craft.com/products/coffret-voyage-en-osmanlie-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/voyage en osmanlie num.png'
    },
    {
        name: 'ARIA : Écran de Jeu',
        shortName: 'Écran de jeu',
        picture: '/assets/shop/écran jeu aria.png',
        isNumericVersion: false,
        url: 'https://elder-craft.com/products/ecran-de-jeu-game-of-roles-aria'
    },
    {
        name: 'ARIA : Le livre des petites Magies',
        shortName: 'Le livre des petites magies',
        picture: '/assets/shop/Livre des petites magies.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/le-livre-des-petites-magies-game-of-roles-aria',
        numericUrl: 'https://elder-craft.com/products/le-livre-des-petites-magies-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/num Livre des petites magies.png.png'
    },
    {
        name: 'ARIA : Deck de magie',
        shortName: 'Deck de magie d\'Aria',
        picture: '/assets/shop/deck magie aria.png',
        isNumericVersion: true,
        url: 'https://elder-craft.com/products/deck-de-magie-game-of-roles-aria',
        numericUrl: 'https://elder-craft.com/products/deck-de-magie-game-of-roles-aria-digital',
        numericPicture: '/assets/shop/num Deck de magie.png'
    },
    {
        name: 'ARIA : Édition Collector',
        shortName: 'Édition collector',
        picture: '/assets/shop/Aria collector.png',
        isNumericVersion: false,
        url: 'https://elder-craft.com/products/edition-collector-game-of-roles-aria'
    },
    {
        name: 'GAME OF RÔLES : Pack Full numérique SU01',
        shortName: 'Pack Full numérique SU01',
        picture: '',
        isNumericVersion: true,
        url: '',
        numericUrl: 'https://elder-craft.com/products/pack-full-numerique-su01-game-of-roles-game-of-roles-digital',
        numericPicture: '/assets/shop/Pack full numerique.jpg'
    },
    {
        name: 'ARIA : Codex de la guerre des deux royaumes',
        shortName: 'Codex de la guerre des deux royaumes',
        picture: '',
        isNumericVersion: true,
        url: '',
        numericUrl: 'https://elder-craft.com/products/codex-de-la-guerre-des-deux-royaumes-game-of-roles-aria',
        numericPicture: '/assets/shop/num Codex de la guerre des deux royaumes.png'
    },
]
