<div class="explanations-container">
    <div class="container" 
        [ngStyle]="{'flex-direction' : flexbox}"
        inViewport
        [inViewportOptions]="{ threshold: [0] }"
        (inViewportAction)="onIntersection($event)">
        <img class="character"  src="{{picture}}" alt="Illustration de {{picture}}">
        <div id="content">
            <div >
                <h2>{{subtitle}}</h2>
                <h3>{{title}}</h3>
                <p [innerHTML]="paragraph"></p>
            </div>
            <div class="keys-container" [ngStyle]="{'transform' : transform}">
                <ul>
                    <li *ngFor="let key of keys">
                        <img class="key" src="/assets/icons/courone-argent.png" alt="couronne en argent">
                        <p>{{key}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
