import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-in',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-out',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inOutAnimationWithDelay',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-in',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.3s ease-out',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]

})
export class HeaderComponent implements OnInit {

  public toggleMenu: boolean = false;
  
  constructor() { }

  ngOnInit(): void {}
  toggleBurgerMenu(): void {
    this.toggleMenu = !this.toggleMenu
  }
}
