import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CartographyComponent } from './cartography/cartography.component';
import { CompagnycreanceComponent } from './compagnycreance/compagnycreance.component';
import { EpisodesComponent } from './episodes/episodes.component';
import { HomeComponent } from './home/home.component';
import { ShopComponent } from './shop/shop.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'aria/cartographie', component: CartographyComponent },
  { path: 'aria/compagnie-créance', component: CompagnycreanceComponent },
  { path: 'aria/episodes', component: EpisodesComponent },
  { path: 'aria/boutique', component: ShopComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AriaRoutingModule { }
