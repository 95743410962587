<app-header></app-header>
<h1>La gamme Aria</h1>
<div class="cards">
    <div [ngStyle]="{'animation-delay': i/5 + 's'}" *ngFor="let product of products; let i = index">
        <div *ngIf="product.url" class="card displayed-left">
            <a href="{{product.url}}" target="_blank"><img src="{{product.picture}}"></a>
            <p>{{product.name}}</p>
        </div>
    </div>
</div>
<div inViewport
[inViewportOptions]="{ threshold: [0] }"
(inViewportAction)="onIntersection($event)">
    <h1>Versions numériques</h1>
    <div class="cards numeric">
        <div [ngStyle]="{'animation-delay': i/5 + 's'}" *ngFor="let product of numericProducts; let i = index">
            <div *ngIf="product.numericUrl" class="card">
                <a href="{{product.numericUrl}}" target="_blank"><img src="{{product.numericUrl ? product.numericPicture : product.picture}}"></a>
                <p>{{product.name}}</p>
                <span>Numérique</span>
            </div>
        </div>
    </div>
    <hr>
</div>
<app-footer></app-footer>