export class Landscape implements Landscape {
    subtitle: string;
    title: string;
    landscape: string;
    map: string;
    paragraph: string;
    flexbox: string;
    color?: string;
    slideInDirection: string;
}

export interface GridLandscape {
    picture: string;
    name: string;
    scroll: number;
}
export const GRIDLANDSCAPE: GridLandscape[] = [
    {picture: 'assets/maps/littleMaps/AriaLarge.jpg', name: 'Aria', scroll: 3870},
    {picture: 'assets/maps/littleMaps/altabianca.jpg', name: 'Altabianca', scroll: 5195},
    {picture: 'assets/maps/littleMaps/PaysMon.jpg', name: 'Pays Môn', scroll: 6580},
    {picture: 'assets/maps/littleMaps/OsmanlieA.jpg', name: 'Osmanlie', scroll: 7930},
    {picture: 'assets/maps/littleMaps/varna.jpg', name: 'Varna', scroll: 9274},
    {picture: 'assets/maps/littleMaps/aqabah.jpg', name: 'Aqabah', scroll: 10625},
    {picture: 'assets/maps/littleMaps/Irem.jpg', name: 'Irem', scroll: 11980},
    {picture: 'assets/maps/littleMaps/kniga.jpg', name: 'Kniga', scroll: 13320}
  ]
export const LANDSCAPES: Landscape[] = [
    {
        title: 'Aria',
        subtitle: 'Capitale du royaume d\'Aria et plus grande cité du monde',
        landscape: 'assets/maps/bigMaps/Aria.jpg',
        map: '/assets/maps/legends/Aria-légende.jpg',
        paragraph: 'La ville est entourée de hauts murs d\'enceintes et accessibles par quelques immenses portes. De nombreux marchands se déplacent pour tenter de faire fortune dans cette cité. Ainsi on peut trouver toutes sortes de marchandises, tant qu\'on est prêt à y mettre le prix, car la vie citadine coûte cher, et à moins d\'avoir un passe-droit, vous devrez vous acquitter de taxe même pour traverser les ponts qui relient les différentes parties de la cité. <br> <br> <br> En effet, la cité est divisée en deux par le fleuve du Parcoul qui finit sa route en se jetant dans la mer de la morsure. La cité est ainsi composée de deux grandes parties et d\'une zone centrale, où deux petits îlots ont pris forme. Depuis l\'un d\'entre eux, un pont majestueux rejoint un immense roc sur lequel surplombe le château royal. <br> <br> <br> À l\'arrivée dans Aria, vous pourrez voir les toits des maisons se toucher et des ruisseaux infects au centre de chaque rue. Des rongefanges, d\'affreux rats noirs, rampent partout. Des mouettes, des albatros et des ronrons, des ratons laveurs affectueux mais très intéressés par la nourriture et n\'hésitant pas à voler. <br> <br> <br> Prostituées, mendiants, camelots, voleurs, gardes, tout le monde vous regarde et tout le monde vous ignore. Vous avez vraiment l\'impression d\'être minuscules dans un monde immense.',
        flexbox: 'row',
        slideInDirection: 'displayed-left'
    },
    {
        title: 'Altabianca',
        subtitle: 'Principauté insulaire et plus riche cité du monde',
        landscape: 'assets/maps/bigMaps/altabianca.jpg',
        map: '/assets/maps/legends/Altabianca-légende.jpg',
        paragraph: '« <i> Vous savez, je n’aurais pas aimé grandir en Altabianca. D’ailleurs, c’est simple, je n’ai pas aimé. Pourquoi? Tout y est trop patiné, trop opulent. Altabianca tire une richesse outrageante de sa place de carrefour maritime. La fortune sourd presque des pores des insulaires! C’est bien simple, seul le parc des Anges vous apporte un peu de naturel, avec ses plantes rarissimes et sa tranquillité hors du temps.</i> » <br> <br> <br> La ville est construite sur un labyrinthe rocheux, sorte de gros rocher morcelé, dont les différentes parties, séparées par de profonds précipices menant aux souterrains de l’île, sont reliées les unes aux autres par de nombreuses arches. La cité est divisée en quartiers appelés « isolottos ». <br> <br> <br>  Altabianca est lourdement protégée : de chaque côté de l’ile – à portée du port marchand – de lourds trébuchets et catapultes sont entretenus par la garde royale qui n’hésite pas à montrer à quiconque la puissance défensive de l’ile <br> <br> <br>  Le symbole d’Altabianca est l’Alcyon, que l’on retrouve décliné en différentes occurrences : le blason de la ville représente un alcyon sur fond azur, les gardes portent de petites ailes dans le dos et chaque famille noble porte un écusson personnel comportant l\'alcyon comme symbole de leur appartenance à l’île.',
        flexbox: 'row-reverse',
        slideInDirection: 'displayed-right'
    },
    {
        title: 'Pays Môn',
        subtitle: 'Territoire autonome peuplé d\'êtres de petite taille',
        landscape: 'assets/maps/bigMaps/PaysMon.jpg',
        map:'/assets/maps/legends/PaysMon-légende.jpg',
        paragraph: '« <i> J’ai demandé à un habitant du pays Môn à quand remontaient les derniers beaux jours et il m’a répondu “J’en sais rien, j’ai que dix-huit ans!”. Alors oui, cette terre est pluvieuse. Mais elle est belle, aussi, et elle ne mérite pas toutes ces invasions du peuple blanc. Elle ne mérite pas qu’on se batte pour elle. Elle ne mérite qu’une seule chose: qu’on la protège... » </i> <br> <br> <br> Le Pays Môn est une région autonome située au nord-ouest du royaume d\'Aria (au nord du Fief des Tempêtes) <br> <br> <br> C\'est un territoire connu pour être très pluvieux et peuplé d\'êtres de petite taille, le peuple Môn. Ses habitants disent que la pluie sont les larmes du Roi des dieux pleurant la mort de son amante, la déesse Môn, mère du peuple Môn, tuée par les hommes du Pays blanc. Le Pays Môn est régulièrement envahi par le peuple blanc venu du Nord, qui tous les hivers viennent piller les ressources du pays et profiter de ses femmes. Le peuple Môn prend cette invasion régulière de son territoire avec un certain stoïcisme, traduit par son fameux dicton : <br> <br> <br> <i> « Mieux vaut vivre à genoux qu\'être mort debout »</i> ',
        flexbox: 'row',
        slideInDirection: 'displayed-left'
    },
    {
        title: 'Osmanlie',
        subtitle: 'Terre de chaos',
        landscape: 'assets/maps/bigMaps/OsmanlieB.png',
        map:'/assets/maps/legends/Osmanlie-légende.jpg',
        paragraph: 'Disposant d’une mer intérieure, de nombreuses richesses naturelles, d’une grande culture et d’une magie facile à manipuler, l’Osmanlie fut jadis la plus grande puissance du monde. <br> <br> <br> Une guerre titanesque éclata entre l\'Osmanlie et toutes les cultures humaines, qui eut pour conséquence de transformer la nuit en jour et de réouvrir le passage vers l’inframonde et ses créatures démoniaques. <br> <br> <br> Pour contenir l\'anarchie de l’Osmanlie et sceller le pays, Varna fit construire une gigantesque muraille partant de la mer et remontant jusqu’au royaume frontalier du Kohest au septentrion. <br> <br> <br> Aux yeux de Varna, l\'Osmanlie en tant que telle n’existait plus et son nomsombra dans l’oubli, pour ne plus être appelée que « le sombre pays ». ',
        flexbox: 'row-reverse',
        slideInDirection: 'displayed-right'
    },
    {
        title: 'Varna',
        subtitle: 'Cité empire et rempart contre l\'Osmanlie',
        landscape: 'assets/maps/bigMaps/varna.jpg',
        map:'/assets/maps/legends/Varna-légende.jpg',
        paragraph: 'La cité empire de Varna se trouve Nord-Est du royaume d\'Aria et au sud de l\'Osmanlie. Son symbole est le lion. <br> <br> <br> Varna dispose d’une ancienne académie de magie appelée « l’Académie des Cinquante » qui est considéré comme la plus grande académie de magie du monde. Le roi de Varna est un homme-chat à la grande crinière de lion. <br> <br> <br> Varna est comme un état-tampon entre le royaume d\'Aria et le khanat d\'Osmanlie; une grande muraille donc la souveraineté dépend de Varna sépare les deux grands états.',
        flexbox: 'row-reverse',
        slideInDirection: 'displayed-left'
    },
    {
        title: 'Aqabah',
        subtitle: 'Maison de la sagesse et des dictons',
        landscape: 'assets/maps/bigMaps/aqabah.jpg',
        map:'/assets/maps/legends/Aqabah-légende.jpg',
        paragraph: 'Aqabah est une contrée qui se trouve à l\ouest du Royaume d\'Aria et à l\'ouest de la Mer de la morsure. <br> <br> <br> La population d\'Aqabah vit essentiellement sur la côte est de son continent, donnant sur « la Morsure », l’océan intérieur du monde. <br> <br> <br> La légende raconte que pour soutenir le Sultanat, il y a bien longtemps, le Roi des Rois a confié le Sceptre Herméneutique à Aqabah. Depuis, son destin est inconnu, Aqabah prétendant l\'avoir rendu et Aria affirmant ne l\'avoir jamais récupéré. Le général Cassandre estime que le sceptre serait caché à Irem. <br> <br> <br> Aqabah est également une région du monde réputée pour ses dictons. <br> <br> <br> <i> « Que la paix soit sur vous , la paix soit également sur vos descendants et sur vos aïeux. Si il y a un jour où il y aura la guerre entre nos peuples, il n\'y aura que la paix entre nous deux. » </i> <br> <br> <i> « Ne dis pas tes peines à autrui, l\'épervier et le vautour s\'abattent sur le blessé qui gémit. » </i>',
        flexbox: 'row',
        slideInDirection: 'displayed-right'
    },
    {
        title: 'Irem',
        subtitle: 'Cité énigmatique aux trésors secrets',
        landscape: 'assets/maps/bigMaps/Irem.jpg',
        map:'/assets/maps/legends/Irem-légende.jpg',
        paragraph: 'Irem est une mystérieuse et énigmatique qui abrite en son sein plusieurs secrets. Le roi des rois est lié à l\'histoire de cette cité, comme semble le montrer les fresques et statues qui ornent l\'endroit et ses alentours. <br> <br> <br> Irem est devenue le centre d\'un conflit entre Aria et Aqabah. Le prince héritier au trône d\'Aria a lancé une croisade contre Aqabah qui est accusée de cacher le sceptre du roi des rois prêté par Aria il y a des années pour aider à gérer un moment de troubles dans le sultanat. ',
        flexbox: 'row-reverse',
        color: '#cecfd0d4',
        slideInDirection: 'displayed-left'
    },
    {
        title: 'Kniga',
        subtitle: 'Cité-état capitale de la science',
        landscape: 'assets/maps/bigMaps/kniga.jpg',
        map:'/assets/maps/legends/Kniga-légende.jpg',
        paragraph: 'Kniga est une cité-état se trouvant sur les terres dorées. La langue et la culture de ce peuple en ont fait des citoyens raffinés, tournés vers les études, et qui préfèrent les intrigues de cour à la conquête. Ses habitants sont facilement reconnaissables sur le pourtour de la mer de la Morsure grâce à leurs yeux de couleur dorée et leurs cheveux blonds. <br> <br> <br> Kniga est l\'un des rares endroits où la magie n\'a pas prise. Celle-ci est remplacée par les sciences, dont l\'alchimie et la mécanique. En tant que civilisation avancée, de grandes machines sillonnent les jungles hostiles. On dit même que certaines volent. <br> <br> <br> Les habitants de Kniga sont très fiers de leur culture et considèrent les autres nations comme des nations de "barbares". Ils sont très hautains et ne croient pas aux dieux ou superstitions de ces contrées lointaines.',
        flexbox: 'row',
        slideInDirection: 'displayed-right'
    },
]