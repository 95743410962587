<div>
    <div class="burger-menu">
        <i class="pi pi-bars" (click)="toggleBurgerMenu()"></i>
    </div>
    <img src="/assets/icons/logo2Aria.png" alt="couronne logo game of roles aria" [routerLink]="['/aria']">
    <ul>
        <li [routerLink]="['/aria/cartographie']" routerLinkActive="visited" routerLinkActiveOptions="{exact:true}">Cartographie</li>
        <li [routerLink]="['/aria/compagnie-créance']" routerLinkActive="visited">Compagnie créance</li>
        <li [routerLink]="['/aria/episodes']" routerLinkActive="visited">Épisodes</li>
        <li [routerLink]="['/aria/boutique']" routerLinkActive="visited">Boutique</li>
    </ul>
</div>
<div [@inOutAnimationWithDelay] *ngIf="toggleMenu" class="menu">
    <li [routerLink]="['/aria/']" routerLinkActive="visited" routerLinkActiveOptions="{exact:true}">Accueil</li>
    <li [routerLink]="['/aria/cartographie']" routerLinkActive="visited" routerLinkActiveOptions="{exact:true}">Cartographie</li>
    <li [routerLink]="['/aria/compagnie-créance']" routerLinkActive="visited">Compagnie créance</li>
    <li [routerLink]="['/aria/episodes']" routerLinkActive="visited">Épisodes</li>
    <li [routerLink]="['/aria/boutique']" routerLinkActive="visited">Boutique</li>
</div>
<div [@inOutAnimation] *ngIf="toggleMenu" class="background-burger-menu"></div>
