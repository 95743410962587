import { Component, OnInit } from '@angular/core';
import { Player, PLAYERS } from '../models/player';

@Component({
  selector: 'app-compagnycreance',
  templateUrl: './compagnycreance.component.html',
  styleUrls: ['./compagnycreance.component.scss']
})
export class CompagnycreanceComponent implements OnInit {

  constructor() { }

  public players: Player[] = PLAYERS;
  public keys: any[] = [
    {image: '/assets/icons/courone-argent.png', key: 'Virevoltants'},
    {image: '/assets/icons/exclamation-argent.png', key: 'Uniques'},
    {image: '/assets/icons/medal.png', key: 'Pas chers'}
  ]

  ngOnInit(): void {
  }
}
