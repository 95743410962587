import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-landscapes',
  templateUrl: './landscapes.component.html',
  styleUrls: ['./landscapes.component.scss']
})
export class LandscapesComponent implements OnInit {

  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public landscape: string = '';
  @Input() public map: string = '';
  @Input() public paragraph: string = '';
  @Input() public flexbox: string = '';
  @Input() public color: string = '';
  @Input() public slideInDirection: string = '';
  
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? this.slideInDirection : 'hidden');
    }
}
