<app-header></app-header>
<div class="container">
    <div class="creance-compagny-container displayed-left">
        <div id="img-container">
            <img class="creance-compagny" src="/assets/characters/compagnie-créance.png" alt="Compangie Créance">
        </div>
        <div id="content">
            <h2>Préambule</h2>
            <h1>La Compagnie Créance</h1>
            <div>
                <p>Si Game Of Roles Aria a connu un si grand succès, c'est aussi grâce à ses quatre personnages joueurs rassemblés sous le nom de La Compagnie Créance. Le groupe, <b> bâti de personnalités toutes aussi différentes que complémentaires, </b> a su charmer les spectateurs au fil de leurs aventures.</p>
                <p>Entre terribles décisions, verve remarquable et proverbes, ils brillent ici par leurs expériences.</p>
                <p>Si on devait les décrire en 3 mots ?</p>
            </div>
            <ul>
                <li class="displayed-swing" *ngFor="let key of keys">
                    <img class="key" src="{{key.image}}" alt="{{key.image}}">
                    <p>{{key.key}}</p>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="separator"></div>
<app-players *ngFor="let player of players"
[subtitle]="player.subtitle"
[title]="player.title"
[nickname]="player.nickname"
[paragraph]="player.paragraph"
[isPlayedBy]="player.isPlayedBy"
[player]="player.player"
[link]="player.link"
[quotes]="player.quotes"
[buble]="player.buble"
[character]="player.character"
[flexbox]="player.flexbox"
[margin]="player.margin"
[secondMargin]="player.secondMargin"
[slideInDirection]="player.slideInDirection">
</app-players>
<hr>
<app-footer></app-footer>