 <div inViewport
 [inViewportOptions]="{ threshold: [0] }"
 (inViewportAction)="onIntersection($event)">
    <div class="landscape-container">
        <img src="{{landscape}}">
        <h1 [innerHTML]="title" [ngStyle]="{'color': color}"></h1>
        <h2 [ngStyle]="{'color': color}">{{subtitle}}</h2>
    </div>
    <div class="map-description-container" [ngStyle]="{'flex-direction' : flexbox}">
        <div>
            <div id="p-container">
                <p [innerHTML]="paragraph"></p>
                <hr>
            </div>
            <img src="{{map}}">
        </div>
    </div>
 </div>