import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product, PRODUCTS } from '../models/product';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  public products: Product[] = PRODUCTS;
  public protagonists: any[] = [
    {name: 'FibreTigre', url: 'https://twitter.com/FibreTigre'},
    {name: 'Lydia', url: 'https://twitter.com/lydia__am'},
    {name: 'Lam', url: 'https://twitter.com/LamHua'},
    {name: 'Daz', url: 'https://twitter.com/dazjdm'},
    {name: 'Deriv', url: 'https://twitter.com/MrDeriv'},
  ]
  ngOnInit(): void {
  }
  navigateTo(page) {
    this.router.navigate([page], {fragment: 'top'})
  }
}
