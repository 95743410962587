import { Component, OnInit, Renderer2 } from '@angular/core';
import { GalleriaService } from 'src/app/shared/aria/galleria.service';
import { Content, CONTENTS} from '../models/content';
import { Quote, QUOTES } from '../models/quote';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public artworks: any;
  public fanarts: any;
  public illustrations: any[];
  public contents: Content[] = CONTENTS;
  public artworkHeaders: any[] = [{title: 'Artworks officiels'}, {title: 'Artworks fans'}]
  public isLoading: boolean = true;
  public isLoaded: boolean = false;
  public  isLoaderLoaded: boolean = false;
  public quotes: Quote[] = QUOTES;
  public quote: Quote;
  public readonly items: number[] = Array(1000).fill(1).map((item, index) => item + index);

  constructor(private galleriaService: GalleriaService, private renderer: Renderer2) {}

  ngOnInit(): void {
    var isChrome = navigator.userAgent.includes("Chrome") && navigator.vendor.includes("Google Inc");
    if (!isChrome) {
      alert('Hola mon brave ! Le site est optimisé pour Google Chrome. Vous profiterez d\'une meilleure expérience utilisateur avec :)')
    }
    this.quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];

    if (sessionStorage.getItem('first loading') === null) {
      setTimeout(() => {
        this.isLoading = false;
        this.isLoaded = true;
        sessionStorage.setItem('first loading', 'false');
      }, 4300);
    }
    if (sessionStorage.getItem('first loading') === 'false') {
      this.isLoaded = true;
      this.isLoading = false;
    }
  this.galleriaService.getArtworks().subscribe(data => {
    this.artworks = data;
    this.galleriaService.getFanarts().subscribe(data => {
      this.fanarts = data;
      const ill = [];
      ill.push(this.artworks.data, this.fanarts.data);
      this.illustrations = ill;  
      })
    })
  }
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'slide-in-left-regular' : 'hidden');
  }
}
