import { Component, OnInit, Renderer2 } from '@angular/core';
import { Product, PRODUCTS } from '../models/product';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  products: Product[] = PRODUCTS;
  numericProducts: Product[] = [];

  ngOnInit(): void {
    this.numericProducts = this.products.filter(product => product.isNumericVersion === true);
  }
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'displayed-left' : 'hidden');
  } 
}
