<app-header></app-header>
<h1>Épisodes de la campagne d'Aria</h1>
<div class="table-container">
    <table>
      <thead>
        <tr>
          <th *ngIf="!slice">Numéro</th>
          <th>Episode</th>
          <th>Titre</th>
          <th>Date</th>
          <th>Écouter</th>
        </tr>
      </thead>
      <tbody>
        <tr [ngStyle]="{'animation-delay': i/30 + 's'}" *ngFor="let episode of episodeList[0]; let i = index">
          <td *ngIf="!slice">{{episode.number}}</td>
          <td>{{episode.episode}}</td>
          <td>{{episode.title}}</td>
          <td>{{episode.date}}</td>
          <td>
            <a href="{{episode.linkToYoutube}}" target="_blank"><img src="assets/icons/logo-youtube.png"> </a>
            <a href="{{episode.linkToSoundcloud}}" target="_blank"><img src="assets/icons/logo-soundcloud.png"> </a>
            <a href="{{episode.linkToSpotify}}" target="_blank"><img src="assets/icons/logo-spotify.png"> </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-footer></app-footer>