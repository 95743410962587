export class Quote {
    name: string;
}

export const QUOTES: Quote[] = [
    {name: '« Le goudron amer de la dignité vaut mieux que le miel de la tranquillité »'},
    {name: '« Conseille l\'ignorant, il te prendra pour son ennemi »'},
    {name: '« Chose donnée de bon coeur vaut son pesant d\'or »'},
    {name: '« N\'ouvre la bouche que si tu es sûr que ce que tu vas dire est plus beau que le silence »'},
    {name: '« Que la paix soit sur vous , la paix soit également sur vos descendants et sur vos aïeux. <br> Si il y a un jour où il y aura la guerre entre nos peuples, il n\'y aura que la paix entre nous deux. »'},
    {name: '« Qui vous connait petit ne vous respecte pas grand »'},
    {name: '« Qui mange seul s’étrangle seul »'},
    {name: '« Une petite pièce donnée à un mendiant avant que le soleil ne se couche, c\'est de la chance pour toute la nuit »'},
    {name: '« Le pardon est la plus belle fleur de la victoire »'},
    {name: '« Les biens de cette vie cessent avec elle. »'},
    {name: '« Ne dis pas tes peines à autrui, l\'épervier et le vautour s\'abattent sur le blessé qui gémit. »'},
]
