import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s ease-in',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.3s ease-out',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class PlayersComponent implements OnInit {

  @Input() public subtitle: string = '';
  @Input() public title: string = '';
  @Input() public nickname: string = '';
  @Input() public paragraph: string = '';
  @Input() public isPlayedBy: string = '';
  @Input() public player: string = '';
  @Input() public link: string = '';
  @Input() public quotes: string = '';
  @Input() public buble: string = '';
  @Input() public character: string = '';
  @Input() public flexbox: string = '';
  @Input() public margin: string = '';
  @Input() public secondMargin: string = '';
  @Input() public slideInDirection: string = '';

  public toggleTwitter: boolean = false;
  
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {}
  
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? this.slideInDirection : 'hidden');
  }
}
