<div class="container">
    <div class="details-container">
        <div>
            <p class="subtitle">plan du site</p>
            <li><a [routerLink]="['/aria']">Accueil</a></li>
            <li><a [routerLink]="['/aria/cartographie']">Cartographie</a></li>
            <li><a [routerLink]="['/aria/compagnie-créance']">Compagnie créance</a></li>
            <li><a [routerLink]="['/aria/episodes']">Épisodes</a></li>
            <li><a [routerLink]="['/aria/boutique']">Boutique</a></li>
            <li><a href="https://gameofroles.wiki/view/Accueil">Wiki communautaire</a></li>
        </div>
        <div>
            <p class="subtitle">protagonistes</p>
            <li *ngFor="let protagonist of protagonists">
                <a href="{{protagonist.url}}" target="_blank">{{protagonist.name}}</a>
            </li>
        </div>
        <div>
            <p class="subtitle">boutique</p>
            <li *ngFor="let product of products">
                <a href="{{product.url}}" target="_blank">{{product.shortName}}</a>
            </li>
        </div>
    </div>
    <div class="creator-container">
        <p>Éditeur : <a href="https://www.elder-craft.com/"><span>Elder-Craft.com</span></a></p>
        <p>Site designé & réalisé avec <i class="pi pi-heart" style="color: #bf3636;"></i> par <a href="https://www.linkedin.com/in/pierre-louis-bastin/">Pierre-Louis Bastin</a></p>

    </div>
</div>