<div class="loader" *ngIf="isLoading">
    <div>
        <img src="/assets/icons/logo2Aria.png" (load)="isLoaderLoaded = true" alt="couronne logo game of roles aria">
        <p *ngIf="isLoaderLoaded">Chargement du contenu...</p>
        <p *ngIf="isLoaderLoaded" [innerHTML]="quote.name"></p>
    </div>
</div>
    
<div [ngClass]="isLoaded ? 'display-content' : 'hide-content'" >
    <app-header id="header"></app-header>
    <div class=container >
        <div class="home-container">
            <div>
                <img id="title" 
                [ngClass]="isLoading ? 'slide-in-left-timeout' : ''"
                [ngClass]="isLoaded ? 'slide-in-left-regular' : ''"
                src="/assets/icons/logoAria.png" alt="Game of roles Aria">
                <div id="p-container" 
                [ngClass]="isLoading ? 'slide-in-left-timeout' : ''"
                [ngClass]="isLoaded ? 'slide-in-left-regular' : ''">
                    <p>Aria est un monde bien réel,</p>
                    <p>qui fait voyager bien au delà des mots, </p>
                    <p>qui ne disparaît pas lorsqu’on referme ses pages...</p>
                </div>
                <!-- <div class="arrow-container">
                    <i class="pi pi-arrow-down"></i>
                </div> -->
            </div>
            <img class="character" 
            [ngClass]="isLoading ? 'slide-in-right-timeout' : ''"
            [ngClass]="isLoaded ? 'slide-in-right-regular' : ''"
            src="assets/characters/Philinda Marteline - Aventuriere.png" alt="Illustration de Philinda Marteline, aventurière">
        </div>
        
    </div>
    <app-explanations *ngFor="let content of contents"
    [subtitle]="content.subtitle"
    [title]="content.title"
    [paragraph]="content.paragraph"
    [keys]="content.keys"
    [flexbox]="content.flexbox"
    [numbered]="content.numbered"
    [slideInDirection]="content.slideInDirection"
    [keyAnimation]="content.keyAnimation"
    [transform]="content.transform">
    </app-explanations>
    
    <div 
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="onIntersection($event)">
        <app-galleria [illustrations]="illustrations"></app-galleria>
    </div>
    <hr>
    
    <app-footer></app-footer> 
</div>

    