export class Content {
    subtitle: string;
    title: string;
    paragraph: string;
    picture: string;
    keys: string[];
    flexbox: string;
    numbered: number;
    slideInDirection: string;
    keyAnimation: string;
    transform: string;
}

export const CONTENTS: Content[] = [
    {
        subtitle : 'préambule',
        title: 'Le jeu de rôles',
        paragraph: 'Le jeu de rôle est un loisir narratif au cours duquel un groupe de personnes se réunit pour <b>imaginer et vivre des aventures extraordinaires</b>. Chaque joueur incarne un personnage d’un univers défini (ici, celui d’ARIA) et décide des actions et du comportement du personnage en question. <br> <br> Dans sa version traditionnelle, un des joueurs se charge de la tâche de meneur de jeu. <b>À la fois animateur, scénariste, metteur en scène et acteur</b>, il fait vivre le monde dans lequel les autres personnages évoluent. Il décrit les situations, interagit avec les autres joueurs et détaille les conséquences des actions de leurs personnages. Le cas échéant, il demande certains jets de dés aux joueurs pour déterminer si l’action tentée est un succès ou non. <br> <br> <b>Le jeu de rôle est donc une succession d’échanges entre les joueurs et le meneur</b>, où chacun s’efforce d’interpréter son personnage et de faire progresser l’action pour que tous puissent s’amuser.',
        picture: '/assets/characters/Honorin Bicastre - mage.png',
        keys: ['Un maitre du jeu', 'un scénario et des tavernes', 'des joueurs'],
        flexbox: 'row',
        numbered: 1,
        slideInDirection: 'displayed-left',
        keyAnimation: 'displayed-swing-one',
        transform: 'translate(-10vw)'
    },
    {
        subtitle : 'actual play',
        title: 'Aria et actual play',
        paragraph: 'Un actual play est un jeu qui a été pensé pour être joué, bien sûr, <b> mais aussi pour être observé par des spectateurs. </b> <br> <br> De ce fait, dans Aria, il y a peu de combats ou de jets de dés. En contrepartie, chaque jet de dé est un moment de suspense qui peut faire basculer la partie. <br> <br> Le succès entraîne une conséquence, et l’échec aussi. Comme dans tout jeu de rôle, la règle suprême est de s’amuser : elle prime sur le respect religieux des règles, de la cohérence ou de l’univers (ou lore, pour reprendre une autre terminologie).',
        picture: '/assets/characters/Harpokhrat Nimh -Laurent-Tourte.png',
        keys: ['en direct', 'surprises & imprévus', 'rythmer chaque partie'],
        flexbox: 'row-reverse',
        numbered: 2,
        slideInDirection: 'displayed-right',
        keyAnimation: 'displayed-swing-two',
        transform: 'translate(-10vw)'

    },
    {
        subtitle : 'émissions et communauté',
        title: 'FibreTigre',
        paragraph: 'Le monde d\'Aria est né de l\'esprit de Fibretigre, <b> auteur français spécialiste de la fiction interactive </b> et grand amateur de fromages (achetés à -50%). <br> <br> Il est le créateur de plusieurs jeux vidéo dont Out There et l\'auteur de livres-jeux et de bandes dessinées. <br> <br> Il lance l\'aventure d\'Aria sous format d\'actual play le 19 févr. 2018 sur la JVTV, chaîne youtube de jeuxvideo.com. Entouré de joueurs pour la plupart débutants, <b> l\'univers imaginé par Fibretigre séduit tout autant que le rôle play et la cohésion des quatre personnages joueurs. </b> <br> <br> Naviguant bon gré mal gré sur la mer agitée du paradigme de l\'émission rentable, le concept parvient à rester ponctuel et réunit une communauté de fans toujours plus investis. <br> <br> Fibretigre lance une campagne de financement participatif Ulule, afin d\'éditer le monde d\'Aria et rencontre un franc succès puisque c\'est <b> 356 231€ qui sont récoltés sur un objectif de 15 000€ </b>, soit un résultat de +2374%. <br> <br> 3 ans plus tard, <b> c\'est dans un cinéma </b>, avec du public et de l\'émotion qu\'a été tourné l\'épisode final de la campagne de Game Of Roles Aria. C\'est également 4 autres campagnes qui ont été créées : Stars : La Grande route, 1979, Chasseurs de Monstres et La Nouvelle Aria.',
        picture: '/assets/characters/Lucilius Altahimir -Jordan-Crampe.png',
        keys: ['3 ans d\'émission', 'une édition papier', '3 nouvelles campagnes'],
        flexbox: 'row',
        numbered: 3,
        slideInDirection: 'displayed-left',
        keyAnimation: 'displayed-swing-three',
        transform: 'translate(-10vw)'
    }
]