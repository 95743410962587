import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Episodes } from '../models/episodes';

@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.scss']
})
export class EpisodesComponent implements OnInit {

  public url = '../../assets/episodes.json';
  public episodes: Episodes;
  public episodeList: string[] = [];
  public slice: boolean = false;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getAll().subscribe(data => {
      this.episodes = data;
      for (const key in this.episodes) {
        if (Object.prototype.hasOwnProperty.call(this.episodes, key)) {
          const element = this.episodes[key];
          this.episodeList.push(element);
        }
      }
    });
    if (window.matchMedia('(max-width: 768px)').matches) {
      this.slice = true;
    }
  }

  getAll(): Observable<Episodes> {
    return this.http.get<Episodes>(this.url);
  }

}
