import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { Observable } from 'rxjs';
import { Artwork } from 'src/app/pages/aria/models/artwork';

@Injectable({
  providedIn: 'root'
})
export class GalleriaService {

  constructor(private http: HttpClient) { }

  getArtworks(): Observable<Artwork[]> {
    return this.http.get<any>('assets/artworks.json')   
  }
  getFanarts(): Observable<Artwork[]> {
    return this.http.get<any>('assets/fanarts.json')
  }
}
